<template>
  <div class="info">

    <div class="banner">
      <div
        :class="'info__banner' + (index + 1)"
        v-for="(item, index) in newBanerList"
        :key="index"
        @click="to('/info/textinfo', item.id)"
      >
        <div class="info__banner-top">
          <img :src="item.url" />
          <span class="tag">文章</span>
        </div>
        <div class="info__banner-bottom">
          <div class="info__banner-bottom-title">
            {{ item.newsTitle }}
          </div>
          <div class="info__banner-bottom-text" v-if="index == 1">
            {{ item.text }}
          </div>
          <div class="info__banner-bottom-user">
            <div class="msg" v-if="index == 1">
              <div class="watchCount">
                <i class="iconfont icon-watchCount"></i>
                <span>{{ bannerList3.viewCount }}</span>
              </div>
              <div class="commentCount">
                <i class="iconfont icon-pinglun2"></i>
                <span>{{ bannerList3.commentCount }}</span>
              </div>
              <div class="likeCount">
                <i class="iconfont icon-dianzanliang"></i>
                <span>{{ bannerList3.likeCount }}</span>
              </div>
            </div>
            <div class="user">
              <div class="username">{{ item.username }}</div>
              <span>·</span>
              <div class="userTime">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info__center">
      <div class="info__center_header">
        <div class="instrument_c_btn baseBtn">
          <div class="bg">
            <button class="title">优安资讯</button>
          </div>
          <div class="info__center_header-nav">
            <div
              :class="{ chose: item.isClick }"
              v-for="(item, index) in choseList"
              :key="index"
              @click="chose(item, index)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="info__center_content">
        <div class="info__center_content-l">
          <release-layout
            :data="list"
            :row="3"
            :id="'layout1'"
            :to="path"
          ></release-layout>

          <div class="more">
            <button v-if="isMore" @click="more">加载更多</button>
          </div>
        </div>
        <div class="info__center_content-r">
          <div :class="{ isFixed: barFixed }">
            <div class="info__center_content-r-info">
              <div class="header">
                <div class="topinfo__r">
                  <div class="line"></div>
                  <div class="topinfo__r_contanier">
                    <div class="topinfo__r_header">
                      <span>热门榜单</span>
                    </div>
                    <div class="topinfo__r_content">
                      <div v-for="(item, index) in hotTopList" :key="index">
                        <div class="content-img top1" v-if="index == 0">
                          <div
                            class="box"
                            @click="to('/info/textinfo', item.newsId)"
                          >
                            <div class="imgBox">
                              <img :src="item.fileId" alt="" />
                            </div>
                            <div class="content-title top1">
                              <!-- <el-button round>点击申请</el-button> -->
                            </div>
                            <div class="text">
                              <div class="author">
                                <el-avatar
                                  :size="32"
                                  :src="item.authorFile"
                                ></el-avatar>
                              </div>
                              <div>{{ item.newsName }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="content-img" v-if="index != 0">
                          <div
                            class="box3"
                            @click="to('/info/textinfo', item.newsId)"
                          >
                            <div class="content-title3">
                              {{ item.newsName }}
                            </div>
                            <div class="content_time">
                              <i class="iconfont icon-time"></i> {{ item.time }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="topinfo__r_content">
                      <div class="content-box"></div>
                    </div>
                    <!-- <div class="topinfo__r_contanier-bottom">
                    阅读更多内容，狠戳这里 👉
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="info__center_content-r-tag">
              <div class="info__center_content-r-tag-header">
                <span>热门标签</span>
              </div>
              <div class="info__center_content-r-tag-content">
                <div v-for="item in tagList" :key="item.id">
                  <el-tag type="info">{{ item.name }}</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="info__center_header">
        <div class="instrument_c_btn baseBtn">
          <div class="info__center_header-nav">
            <div class="chose">最新</div>
            <div>推荐</div>
            <div>号外</div>
            <div>综合</div>
          </div>
        </div>
      </div>
      <div class="info__center_content masonry">
        <masonry ></masonry>
      </div>-->
    </div>
  </div>
</template>
<script>
// import masonry from "../components/masonry.vue";
import releaseLayout from "../components/releaseLayout.vue";
import info from "../api/info";
import lib from "../api/lib";
import api from "../api/api";
export default {
  data() {
    return {
      list: [],
      bannerLists: [],
      bannerList1: null,
      bannerList2: null,
      bannerList3: null,
      choseList: [
        {
          title: "最新",
          key: "latest",
          isClick: false,
        },
        {
          title: "推荐",
          key: "hots",
          isClick: false,
        },
        {
          title: "热门",
          key: "recommend",
          isClick: false,
        },
        {
          title: "综合",
          key: "all",
          isClick: false,
        },
      ],
      hotTopList: [],
      tagList: [],
      path: {
        url: "/info/textinfo",
      },
      barFixed: false,
      isMore: true,
      pages: 1,
      type: "",
    };
  },
  components: { releaseLayout },
  watch: {
    choseList: {
      handler(newValue, oldValue) {
        let data = newValue.filter((val) => {
          return val.isClick;
        });
        this.type = data[0].key;
        let senedData = {
          type: data[0].key,
          page: 1,
        };
        this.newsList(senedData);
      },
      deep: true,
    },

    pages(val) {
      let senedData = {
        type: this.type,
        page: this.pages,
      };
      this.newsList(senedData, true);
    },
  },
  created() {
    this.choseList[0].isClick = true;
    this.bannerList();
    this.topList();
    this.getTags();
  },
  computed: {
    newBanerList() {
      let list = this.Time(this.bannerLists);
      return list.slice(0, 3);
    },
     
    
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
  },

  methods: {
    to(path, id) {
      this.$router.push(`${path}/${id}`);
    },
    chose(item, index) {
      this.choseList.forEach((item) => {
        item.isClick = false;
      });
      this.choseList[index].isClick = true;
      this.choseList.splice(this.choseList.length);
    },
    getTags() {
      info.getTags().then((res) => {
        this.tagList = res.data.data;
      });
    },
    indexTopNews(data) {
      return new Promise((resolve, reject) => {
        api.indexTopNews(data).then((res) => {
          resolve(res.data.data.data);
        });
      });
    },

    bannerList() {
      this.indexTopNews().then((resolve) => {
        let data = resolve;
        let newList = [];
        data.forEach((item) => {
          if (item.description.length > 120) {
            item.description = item.description.substr(0, 120) + "...";
          }
          if (item.newsName.length > 16) {
            item.newsName = item.newsName.substr(0, 16) + "...";
          }
          let newData = {
            id: item.newsId,
            username: item.author,
            authorFile: item.authorFile,
            time: item.createTime,
            url: item.fileId,
            newsTitle: item.newsName,
            viewCount: item.newsViews,
            commentCount: item.commentNum,
            likeCount: item.newsPros,
            text: item.description,
          };
          newList.push(newData);
        });
        this.bannerLists = newList;
        this.bannerList1 = newList[0];
        this.bannerList2 = newList[1];
        this.bannerList3 = newList[2];
      });
    },
    newsList(data, flag) {
      info.newsList(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;

        list.forEach((item) => {
          let data = {
            id: item.newsId,
            author: item.author,
            userIcon: item.authorFile,
            time: item.createTime,
            url: item.fileId,
            newsTitle: item.newsName,
            viewCount: item.newsViews,
            commentCount: item.commentNum,
            likeCount: item.newsPros,
            text: item.description,
          };
          newList.push(data);
        });
        if (newList.length == 0) {
          this.isMore = false;
        }
        this.list = flag ? [...this.list, ...newList] : newList;
      });
    },
    topList() {
      let data = {
        type: "hots",
      };
      this.indexTopNews(data).then((resolve) => {
        resolve.forEach(item=>{
          item.time = item.createTime
        })
        let list = this.Time(resolve);
      
        this.hotTopList = list.slice(0, 4);
      });
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop = document.querySelector(
        ".info__center_content-r"
      ).offsetTop;

      if (scrollTop > offsetTop - 170) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }
    },
    more() {
      this.pages++;
    },
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style lang="scss">
.info__center_content-r-tag-content {
  .el-tag {
    border-radius: 27px;
  }
}
</style>
<style lang="scss" scoped>
.info {
  .banner {
    display: grid;
    grid-template-columns: auto 800px;
    grid-template-rows: auto auto;
    grid-column-gap: 27px;
    grid-row-gap: 33px;
    .info__banner1 {
      border-radius: 7px;
      overflow: hidden;
      padding: 20px;
      background: #fff;
      .info__banner-top {
        overflow: hidden;
        border-radius: 7px;
        position: relative;
        .tag {
          position: absolute;
          padding: 8px 17px;
          left: 13px;
          top: 13px;
          background: #384148;
          opacity: 0.6;

          border-radius: 25px;
          font-size: 12px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;
        }
      }
      img {
        width: 100%;
        border-radius: 7px;
        transition: all 0.5s;
      }
      .info__banner-bottom {
        .info__banner-bottom-title {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 13px;
        }
        .info__banner-bottom-user {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
          span {
            margin: 0 10px;
          }
          .user {
            display: flex;
            align-items: center;
          }
        }
      }

      &:hover {
        box-shadow: 0px 5px 21px 1px
          rgba(
            11.404168456792831,
            73.7658217549324,
            119.00000050663948,
            0.17000000178813934
          );
        img {
          transform: scale(1.05);
        }
      }
    }
    .info__banner2 {
      border-radius: 7px;
      overflow: hidden;

      background: #fff;
      .info__banner-top {
        overflow: hidden;
        border-radius: 7px;
        position: relative;
        .tag {
          position: absolute;
          padding: 8px 17px;
          left: 13px;
          top: 13px;
          background: #384148;
          opacity: 0.6;

          border-radius: 25px;
          font-size: 12px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;
        }
      }
      img {
        width: 100%;
        border-radius: 7px;
        transition: all 0.5s;
      }

      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
      .info__banner-bottom {
        padding: 0 40px 32px 40px;
        .info__banner-bottom-title {
          font-size: 35px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-top: 40px;
        }
        .info__banner-bottom-text {
          font-size: 17px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 27px;
          margin-bottom: 45px;
        }
        .info__banner-bottom-user {
          display: flex;
          justify-content: space-between;
          .msg {
            display: flex;
            align-items: center;
            .watchCount,
            .commentCount,
            .likeCount {
              
              i {
                margin-right: 8px;
              }
              display: flex;
              align-items: center;
              margin-right: 36px;
              color: #cde0e9;
            }
            .watchCount {
              i {
                font-size: 28px;
              }
            }
            .commentCount {
              i {
                font-size: 21px;
              }
            }
            .likeCount {
              font-size: 16px;
            }
          }
          .user {
            display: flex;
            align-items: center;
            font-size: 17px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            span {
              margin: 0 10px;
            }
          }
        }
      }
      &:hover {
        box-shadow: 0px 5px 21px 1px
          rgba(
            11.404168456792831,
            73.7658217549324,
            119.00000050663948,
            0.17000000178813934
          );
        img {
          transform: scale(1.05);
        }
      }
    }
    .info__banner3 {
      border-radius: 7px;
      overflow: hidden;
      padding: 20px;
      background: #fff;
      .info__banner-top {
        overflow: hidden;
        border-radius: 7px;
        position: relative;
        .tag {
          position: absolute;
          padding: 8px 17px;
          left: 13px;
          top: 13px;
          background: #384148;
          opacity: 0.6;

          border-radius: 25px;
          font-size: 12px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;
        }
      }
      img {
        width: 100%;
        border-radius: 7px;
        transition: all 0.5s;
      }
      .info__banner-bottom {
        .info__banner-bottom-title {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 13px;
        }
        .info__banner-bottom-user {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
          span {
            margin: 0 10px;
          }
          .user {
            display: flex;
            align-items: center;
          }
        }
      }
      &:hover {
        box-shadow: 0px 5px 21px 1px
          rgba(
            11.404168456792831,
            73.7658217549324,
            119.00000050663948,
            0.17000000178813934
          );
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  .info__banner {
    .info__banner_container {
      display: flex;
      .info__banner_container-l {
        width: 415px;
        margin-right: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .info__banner_container-r {
      }
      .info__banner_container-box {
        padding: 20px;
        background: #fff;
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        transition: all 0.5s;
        .imgbox {
          overflow: hidden;
        }
        &:hover {
          box-shadow: 0px 5px 21px 1px
            rgba(
              11.404168456792831,
              73.7658217549324,
              119.00000050663948,
              0.17000000178813934
            );
          img {
            transform: scale(1.05);
          }
        }
        &.margin {
          margin-bottom: 20px;
        }
        .info__banner_container-content {
        }
        .info__banner_container-box-text {
          margin-top: 25px;
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
        }
        img {
          width: 100%;
          transition: all 0.5s;
        }
        &.col {
          width: 800px;
          padding: 0;
          .tag {
            left: 13px;
            top: 13px;
          }
          .info__banner_container-content {
            padding: 40px 40px 33px 40px;
          }

          .info__banner_container-box-text {
            margin-top: 0px;

            font-size: 35px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #333333;

            p {
              margin-top: 27px;
              font-size: 17px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .box_article_b-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          &.top {
            margin-top: 45px;
          }
          .box_article_b-title {
            font-size: 21px;
            font-weight: 500;
            color: #2d3f56;
            font-family: PingFang SC-Medium, PingFang SC;
            line-height: 25px;
            text-align: center;
            margin-top: 13px;
          }
          .box_article_b-text {
            font-size: 17px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-top: 15px;
          }
          .box_article_b-user {
            font-size: 17px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 20px;
            display: flex;
            // margin-top: 24px;
            align-items: center;
            div {
              margin: 0 9px;
            }
          }
          .box_article_b-count {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #cee0ea;
            line-height: 19px;
            display: flex;
            // margin-top: 24px;
            // margin-bottom: 35px;
            i {
              margin: 0 8px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .info__center {
    .info__center_header {
      .baseBtn {
        display: flex;
        align-items: end;
        margin: 35px 0;
        justify-content: space-between;
        .bg {
          width: 216px;
          display: flex;
          align-items: flex-end;
          height: 88px;
          background: url("../assets/btn-background.png");
          background-size: 100% 100%;
        }
        .title {
          width: 180px;
          height: 53px;
          background: linear-gradient(
            90deg,
            #0092ff 0%,
            rgba(55, 182, 255, 0.8700000047683716) 100%
          );
          box-shadow: 0px 5px 9px 1px
            rgba(77.99999535083771, 191.00001901388168, 255, 0.25);
          border-radius: 39px 39px 39px 0px;
          border: none;
          font-size: 29px;
          font-weight: 800;
          color: #ffffff;
          font-family: PingFang Regular;
        }
        .text {
          margin: 0 25px;
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .serch__box {
          flex-grow: 1;
          text-align: right;
        }
      }
      .info__center_header-nav {
        display: flex;
        width: 513px;
        border-bottom: 1px solid #d2d4d6;
        margin-right: 347px;
        cursor: pointer;
        div {
          padding: 8px 24.5px 16px 24.5px;
          position: relative;
          color: #999999;
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          &:nth-child(1) {
            padding-left: 0;
            &.chose {
              &::after {
                left: 20.5px;
              }
            }
          }
          &.chose {
            color: #0394ff;
            &::after {
              display: block;
              content: "";
              width: 48px;
              height: 8px;
              background: #0394ff;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    .info__center_content {
      display: flex;
      justify-content: space-between;
      &.masonry {
        display: block;
        width: 1300px;
      }
      .info__center_content-l {
        width: 894px;
        .more {
          margin-top: 143px;
          margin-bottom: 143px;
          text-align: center;
          button {
            width: 213px;
            height: 64px;
            font-size: 21px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0394ff;
            border-radius: 65px;
            background: #fbfbfb;
            border: 1px solid #0394ff;
            cursor: pointer;
            &:hover {
              box-shadow: 0px 5px 24px 1px
                rgba(
                  2.9999998211860657,
                  147.9999303817749,
                  255,
                  0.5099999904632568
                );
              background: #0394ff;
              color: #ffffff;
            }
          }
        }
      }
      .info__center_content-r {
        width: 296px;
        .isFixed {
          position: fixed;
          top: 153px;
          width: 296px;
        }
        .info__center_content-r-tag {
          border: 1px dashed #5e5e5e;
          .info__center_content-r-tag-header {
            padding: 13px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0394ff;
            border-bottom: 1px dashed #5e5e5e;
            span {
              padding: 13px;
              border-bottom: 1px solid #0394ff;
            }
          }
          .info__center_content-r-tag-content {
            display: flex;
            flex-wrap: wrap;
            //   justify-content: ;
            div {
              margin: 10px 10px;
            }
          }
        }
        .info__center_content-r-info {
          margin-bottom: 40px;
          margin-top: 17px;
          .header {
            .topinfo__r {
              //   width: 328px;
              border-radius: 7px;
              // border-top: 5px solid #0394FF;

              .line {
                height: 5px;
                background: #0394ff;
                border-radius: 7px 7px 0 0;
              }
              .topinfo__r_contanier {
                border: 1px solid #e9e9e9;
                background: #ffffff;
                border-radius: 0 0 7px 7px;
                .topinfo__r_header {
                  padding: 10px 19px;
                  span {
                    padding: 5px 0;
                    border-bottom: 3px solid #0092ff;
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #0394ff;
                  }
                }
              }

              .topinfo__r_content {
                .content-img {
                  padding: 13px 16px;
                  &.top1 {
                    padding: 21px 16px 0 16px;
                    .box {
                      border-radius: 7px;
                      background: #ffffff;
                      box-shadow: 0px 1px 24px 1px
                        rgba(0, 0, 0, 0.07000000029802322);
                      .imgBox {
                        overflow: hidden;
                        border-radius: 7px;
                        img {
                          transition: all 0.5s;
                        }
                      }
                      .text {
                        display: flex;
                        padding: 13px 21px;
                        background: #fff;
                        border-radius: 0 0 7px 7px;

                        .author {
                          margin-right: 16px;
                        }
                      }
                      &:hover {
                        img {
                          transform: scale(1.05);
                        }
                      }
                    }
                  }
                  .box {
                    position: relative;
                    img {
                      width: 100%;
                      border-radius: 7px 7px 0 0;
                    }
                  }
                  .box2 {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    grid-template-rows: auto auto;
                    grid-column-gap: 15px;
                    grid-row-gap: 15px;
                    img {
                      width: 100%;
                      border-radius: 7px;
                      grid-row-start: 1;
                      grid-row-end: 3;
                    }
                    .content-title2 {
                      font-size: 17px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #333333;
                    }
                    .content-time {
                      display: flex;
                      align-items: flex-end;
                      font-size: 16px;
                      font-family: Helvetica-Regular, Helvetica;
                      font-weight: 400;
                      color: #b1b1b1;
                    }
                  }
                  .box3 {
                    .content-title3 {
                      // padding: 20px 0;
                      font-size: 19px;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #666666;
                    }
                    .content_time {
                      font-size: 16px;
                      font-family: Roboto-Regular, Roboto;
                      font-weight: 400;
                      color: #b1b1b1;
                      padding: 5px 0;
                      border-bottom: 1px solid #d2d4d6;
                      i{
                        margin-right: 11px;
                      }
                    }
                    &:hover {
                      .content-title3 {
                        color: #0394ff;
                      }
                    }
                  }
                }

                .content-title {
                  &.top1 {
                    //   width: 217px;
                    position: absolute;
                    padding: 13px 21px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    //   border: 1px solid #ffffff;
                    font-size: 19px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
              }
              .topinfo__r_contanier-bottom {
                padding: 9px 0;
                text-align: center;
                background: #0394ff;
                color: #fff;
                border-radius: 0 0 7px 7px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
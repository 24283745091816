import request from '../util/request';

const apis = {
    indexTopApparatus(params) {
        return request({
            url: '/V1/indexTopApparatus',
            method: 'get',
            params
        })
    },
    bannerList(data) {
        return request({
            url: `/V1/bannerList?bannerPage=${data.bannerPage}&bannerPosition=${data.bannerPosition}`,
            method: 'get',

        })
    },
    indexTopRank(data) {
        return request({
            url: `/V1/indexTopRank`,
            method: 'get',

        })
    },
    indexTopNews(params) {
        return request({
            url: `/V1/indexTopNews`,
            method: 'get',
            params
        })

    },
    indexTopAssess(params) {
        return request({
            url: `/V1/indexTopAssess`,
            method: 'get',
            params
        })
    },
    indexTopAComment() {
        return request({
            url: `/V1/indexTopAComment`,
            method: 'get',

        })
    },
    operate(data) {
        return request({
            url: `/V1/operate/${data.type}/${data.operate}`,
            method: 'post',
            data: data.id
        })
    },

    /**
     * 用户收藏
     */
    collectAdd(data) {
        return request({
            url: `/V1/collectAdd`,
            method: 'post',
            data
        })
    },
    /**
     * 用户点赞/取消
     */
    handlePros(data) {
        return request({
            url: `/V1/operate`,
            method: 'post',
            data
        })
    },
    /**
     * 用于点评投诉的器械详情
     */
    apparatusCommentInfo(params) {
        return request({
            url: `/V1/apparatusCommentInfo`,
            method: 'get',
            params
        })
    },


    /**
     * 用户点评排行榜
     */
    indexTopComment(params) {
        return request({
            url: `/V1/indexTopComment`,
            method: 'get',

        })
    },
    /**
     * 用户投诉排行榜
     */
    indexTopComplain(params) {
        return request({
            url: `/V1/indexTopComplain`,
            method: 'get',

        })
    }
}

export default apis

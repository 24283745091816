<template>
  <div class="instrument_c_box" :id="id">
    <div class="box" v-for="(item, index) in cInstrumentList" :key="index">
      <div
        class="box_article"
        :class="type"
        @click="toPath(item.id, item.catId, item.code)"
      >
        <div class="box_article_t">
          <img v-if="item.url" :src="item.url" alt="" />
          <img v-if="!item.url" src="../assets/test.png" alt="" />
          <span v-if="!type">文章</span>
          <span class="icon icon0" v-if="item.order == 2">未开始</span>
          <span class="icon icon1" v-if="item.order == 3">已过期</span>
          <span class="icon icon2" v-if="item.order == 1">申请中</span>
        </div>

        <div class="box_article_b" v-if="!type">
          <div class="box_article_b-title">{{ item.newsTitle }}</div>
          <div class="box_article_b-text">{{ item.text }}</div>
          <div class="box_article_b-box">
            <div class="box_article_b-user">
              <div style="height: 27px">
                <el-avatar :size="27" :src="item.userIcon"></el-avatar>
              </div>

              <div class="userTtle" :title="item.author">{{ item.author }}</div>
              <span class="cle">·</span>
              <div class="userTime">{{ item.time }}</div>
            </div>
            <div class="box_article_b-count">
              <div class="watchCount">
                <i class="iconfont icon-watchCount"></i>{{ item.viewCount }}
              </div>
              <div class="commentCount">
                <i class="iconfont icon-pinglun2"></i>{{ item.commentCount }}
              </div>
              <div class="likeCount">
                <i class="iconfont icon-dianzanliang"></i>{{ item.likeCount }}
              </div>
            </div>
          </div>
        </div>

        <div class="box_article_b" v-if="type == 'check1'">
          <div class="box_article_b-title">{{ item.newsTitle }}</div>
          <div class="box_article_b-text">{{ item.text }}</div>
          <div class="box_article_b-box">
            <div class="box_article_b-user">
              <div>
                <el-avatar :size="27" :src="item.userIcon"></el-avatar>
              </div>

              <div class="userTtle">{{ item.author }}</div>
              <span class="cle">·</span>
              <div class="userTime">{{ item.time }}</div>
            </div>
            <div class="box_article_b-count">
              <div class="watchCount">
                <i class="iconfont icon-watchCount"></i>{{ item.viewCount }}
              </div>
              <div class="commentCount">
                <i class="iconfont icon-pinglun2"></i>{{ item.commentCount }}
              </div>
              <div class="likeCount">
                <i class="iconfont icon-dianzanliang"></i>{{ item.likeCount }}
              </div>
            </div>
          </div>
        </div>
        <div class="box_article_b" v-if="type == 'check2'">
          <div class="box_article_b-title">优安众测：{{item.apparatusType}}{{ item.newsTitle }}</div>
          <div class="box_article_b-text">{{ item.text }}</div>
          <div class="box_article_b-box">
            <div class="box_article_b-user">
              <div>
                <el-avatar :size="27" :src="item.userIcon"></el-avatar>
              </div>

              <div class="userTtle">{{ item.author }}</div>
              <span class="cle">·</span>
              <div class="userTime">{{ item.time }}</div>
            </div>
            <div class="box_article_b-count">
              <div class="watchCount">
                <i class="iconfont icon-watchCount"></i>{{ item.viewCount }}
              </div>
              <div class="commentCount">
                <i class="iconfont icon-pinglun2"></i>{{ item.commentCount }}
              </div>
              <div class="likeCount">
                <i class="iconfont icon-dianzanliang"></i>{{ item.likeCount }}
              </div>
            </div>
          </div>
        </div>
        <div class="box_article_b" v-if="type == 'text'">
          <div class="box_article_b-title">{{ item.newsTitle }}</div>
          <div class="box_article_b-box">
            <div class="text_l" :title="item.manufactor">
              厂家:{{ item.manufactor }}
            </div>
            <div class="text_price" v-if="item.price">￥{{ item.price }}</div>
          </div>
        </div>

        <div class="box_article_b" v-if="type == 'score'">
          <div class="box_article_b-title">{{ item.newsTitle }}</div>
          <div class="box_article_b-box">
            <div class="text_l">综合评分</div>
            <div class="text_price">{{ item.score }}</div>
          </div>
        </div>

        <div class="box_article_b top" v-if="type == 'top'">
          <div class="box_article_b-title" style="text-align: left">
            {{ item.rankName }}
          </div>
          <div class="box_article_b-title time" style="text-align: left">
            {{ item.createTime }}
          </div>
        </div>
        <div class="box_article_b check" v-if="type == 'check'">
          <div class="box_article_b-title headerTitle">
            {{ item.name }}
          </div>
          <div class="box_article_b-title" style="text-align: left">
            数量:{{ item.quantify }}
          </div>
          <div class="box_article_b-title" style="text-align: left">
            申请人数:{{ item.applyNum }}
          </div>
          <div class="box_article_b-title" style="text-align: left">
            申请积分:{{ item.condition }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  name: "releaseLayout",
  props: ["data", "row", "id", "type", "to"],
  computed: {
    cInstrumentList() {
      for (const i in this.data) {
        let data = this.data[i];
        let dateTimeStamp = new Date(data.time).getTime();
        let minute = 1000 * 60;
        let hour = minute * 60;
        let day = hour * 24;

        let month = day * 30;
        let year = month * 12;
        let now = new Date().getTime();
        let diffValue = now - dateTimeStamp;
        let result = "";
        if (diffValue < 0) {
          return;
        }

        let monthC = diffValue / month;
        let weekC = diffValue / (7 * day);
        let dayC = diffValue / day;
        let hourC = diffValue / hour;
        let minC = diffValue / minute;
        let yearC = diffValue / year;
        if (yearC >= 1) {
          return "" + parseInt(yearC) + "年前";
        }
        if (monthC >= 1) {
          result = "" + parseInt(monthC) + "月前";
        } else if (weekC >= 1) {
          result = "" + parseInt(weekC) + "周前";
        } else if (dayC >= 1) {
          result = "" + parseInt(dayC) + "天前";
        } else if (hourC >= 1) {
          result = "" + parseInt(hourC) + "小时前";
        } else if (minC >= 1) {
          result = "" + parseInt(minC) + "分钟前";
        } else {
          result = "刚刚";
        }

        data.time = result;
      }

      return this.data;
    },
  },
  watch: {
    data(n) {
      for (let i = 0; i < n.length; i++) {
        if (n[i].text) {
          if (n[i].text.length > 25) {
            n[i].text = n[i].text.substring(0, 25) + "...";
          }
        }
        if (n[i].newsTitle) {
          if (n[i].newsTitle.length > 11) {
            n[i].newsTitle = n[i].newsTitle.substring(0, 11) + "...";
          }
        }
      }
      this.$nextTick(() => {
        this.layoutInit();
      });
    },
  },
  created() {},
  mounted() {
    // this.$nextTick(()=>{
    //   console.log(this.data)
    //   this.layoutInit()
    // })
  },
  methods: {
    layoutInit() {
      let masonry = this.Macy({
        container: `#${this.id}`,
        trueOrder: true,

        waitForImages: false,
        useOwnImageLoader: false,
        debug: true,
        columns: this.row,
        margin: {
          x: 15,
          y: 15,
        },
      });
    },
    toPath(id, catId, code) {
      let url1,
        url2,
        url3 = "";

      url1 = id ? `/${id}` : "";
      url2 = catId ? `/${catId}` : "";
      url3 = code ? `/${code}` : "";

      let allUrl = url1 + url2 + url3;
      this.$router.push({
        path: `${this.to.url}${allUrl}`,
      });
      this.$emit("child-event");
    },
  },
};
</script>
<style lang="scss" scoped>
.instrument_c_btn {
}
.instrument_c_box {
  .box {
    .box_article {
      // width: 280px;
      padding: 17px 12px 37px 12px;
      // background: #ffffff;
      // margin: 2px;
      &:hover {
        border-radius: 7px;
        background: #ffffff;
        box-shadow: 0px 5px 21px 1px
          rgba(
            11.404168456792831,
            73.7658217549324,
            119.00000050663948,
            0.17000000178813934
          );
        img {
          transform: scale(1.05);
        }
      }
      &.score {
        padding: 0;
        background: #f6f6f6;
        border-radius: 7px;
        .box_article_b {
          padding: 8px 11px;
          .box_article_b-title {
            font-size: 16px !important;
          }
          .text_l {
            font-size: 16px !important;
            font-family: PingFang SC-Regular, PingFang SC !important;
            font-weight: 400 !important;
            color: #999999;
            margin: 15px 0 !important;
            width: auto !important;
          }
          .text_price {
            margin: 0 !important;
            font-size: 19px !important;
            font-family: Helvetica-Bold Oblique, Helvetica;
            font-weight: normal;
            color: #ff5c5c;
          }
        }
      }
      &.check {
        padding: 0;
        border-radius: 7px;
      }
      &.check1 {
        .box_article_b {
          .box_article_b-box {
            display: block;
            .box_article_b-user {
              span {
              }
            }
          }
        }
      }
      .box_article_t {
        // min-height: 188px;
        // width: 280px;
        position: relative;
        border-radius: 7px;
        overflow: hidden;

        img {
          width: 100%;
          border-radius: 7px;
          transition: all 0.5s;
          height: 150px;
        }
        span {
          position: absolute;
          padding: 8px 17px;
          left: 10px;
          top: 10px;
          background: #384148;
          opacity: 0.6;

          border-radius: 25px;
          font-size: 12px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;
        }
        .icon {
          position: absolute;
          left: 12px;
          top: 13px;
          padding: 5px 12px;
          border-radius: 23px;
          font-size: 16px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;

        }
        .icon0 {
          background: #426e8f;
          opacity: 1;
        }
        .icon1 {
          background: #6f6f6f;
          opacity: 1;
        }
        .icon2 {
          background:#d46b2f ;
          opacity: 1;
        }
      }
      .box_article_b {
        padding: 0px 16px;
        .box_article_b-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 0 5px;
          .text_l {
            font-size: 17px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-top: 35px;
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .text_price {
            font-size: 17px;
            font-family: Helvetica-Oblique, Helvetica;
            font-weight: normal;
            color: #ff5c5c;
            margin-top: 35px;
          }
        }
        .box_article_b-title {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #2d3f56;
          // line-height: 25px;
          text-align: left;
          margin-top: 13px;
          overflow: hidden;
          height: 30px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .box_article_b-text {
          font-size: 17px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-top: 15px;
        }
        .box_article_b-user {
          font-size: 17px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 20px;
          display: flex;
          margin-top: 24px;
          align-items: center;
          .userTtle {
            max-width: 57px;
            overflow: hidden;
            text-overflow: ellipsis;

            white-space: nowrap;
            margin-left: 9px;
            margin-right: 5px;
          }
          .cle {
            margin: 0 5px;
          }
        }
        .box_article_b-count {
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #cee0ea;
          line-height: 19px;
          display: flex;
          margin-top: 24px;
          align-items: center;
          // margin-bottom: 35px;
          div {
            margin-right: 20px;
          }
          .watchCount,.commentCount.likeCount{
            display: flex;
            align-items: center;
          }
          .watchCount {
            i {
              font-size: 28px;
            }
          }
          .commentCount {
            i {
              font-size: 22px;
            }
          }
          .likeCount {
            i {
              font-size: 16px;
            }
          }
          i {
            margin-right: 8px;
          }
        }
        &.check {
          padding: 13px 17px;
          background: #ffffff;
          border-radius: 7px;
          .box_article_b-title {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 7px;
            &:nth-child(2){
              margin-top: 13px;
            }
            &.headerTitle {
              font-size: 21px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #2d3f56;
              margin-top: 0px;
            }
          }
        }
        &.top {
          padding: 0 16px;
          .box_article_b-title {
            font-size: 21px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #2d3f56;
            margin-top: 21px;
            &.time {
              font-size: 17px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #999999;
              margin-top: 35px;
            }
          }
        }
      }
    }
  }
  &.score {
  }
}
</style>

import request from '../util/request';

const apis = {
    newsList(params) {
        return request({
            url: "/V1/newsList",
            method: 'get',
            params
        })
    },
    newsInfo(params) {
        return request({
            url: "/V1/newsInfo",
            method: 'get',
            params
        })
    },
    getNewsComment(data) {
        return request({
            url: "/V1/getNewsComment",
            method: 'get',
            params: data
        })
    },
    addNewsComment(data) {
        return request({
            url: "/V1/addNewsComment",
            method: 'POST',
            params: {
                newsId: data.newsId
            },
            data: {
                comment: data.comment
            }
        })
    },
    getTags() {
        return request({
            url: "/V1/getTags",
            method: 'get',
        })
    }


}
export default apis

import request from '../util/request';
const apis = {
    getFirm() {
        return request({
            url: '/V1/getFirm',
            method: 'get',
        })
    },
    getCate(type) {
        return request({
            url: `/V1/getCate/${type}`,
            method: 'get',
        })
    },
    searchApparatus(data) {

        return request({
            url: `/V1/searchApparatus`,
            method: 'get',
            params: data
        })
    },
    infoApparatus(data) {
        return request({
            url: "/V1/infoApparatus",
            method: 'get',
            params: { apparatusId: data.id }
        })
    },
    commentApparatus(data) {
        return request({
            url: "/V1/commentApparatus",
            method: 'get',
            params: {
                apparatusId: data.apparatusId,
                page: data.page
            }
        })
    },
    complainApparatus(data) {
        return request({
            url: "/V1/complainApparatus",
            method: 'get',
            params: {
                apparatusId: data.apparatusId,
                page: data.page
            }
        })
    },
    getFCate(data) {
        return request({
            url: "/V1/getFCate",
            method: 'get',
            params: {
                firmId: data.firmId
            }
        })
    },
    diffParam(data) {
        return request({
            url: "/V1/diffParam",
            method: 'put',
            data
        })
    },
    diffComment(data) {
        return request({
            url: "/V1/diffComment",
            method: 'put',
            data
        })
    }
}

export default apis
